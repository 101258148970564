import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {NavItemExternal, NavItem as NavItemInternal} from 're-cy-cle';
import {omit, some, trim} from 'lodash';
import {BackButton} from '../Button';

// v1 stack is stuck in lodash < 4.0.0, which does not have trimEnd.
function trimEnd(str, char) {
    const result = trim(str, char);

    if (str[0] === char) {
        return char + result;
    }

    return result;
}

const baseUrl = trimEnd((process.env.CY_FRONTEND_BASE_URL || ''), '/');

var parser = document.createElement('a');
parser.href = baseUrl;
const basePathname = baseUrl ? parser.pathname : '';

// v2 stack has a prefix: http://aaa-lux.test/v2/
export let V2_URL_PREFIX = '/v2';

export function configureV2UrlPrefix(prefix) {
    V2_URL_PREFIX = prefix;
}

export function generateV2Url(url) {
    return basePathname + V2_URL_PREFIX + url;
}

const OMIT_PROPS = ['show', 'className'];


const extractHash = function (url) {
    if (typeof url === 'string') {
        return url.substring(url.indexOf('#'));
    }

    return url;
}

export function checkActiveV1(...urls) {
    return some(urls, url => {
        return window.location.hash.startsWith(extractHash(url));
    });
}

export function checkActiveV2(...urls) {
    return some(urls, url => {
        return window.location.pathname.startsWith((basePathname + V2_URL_PREFIX + url).replace('#', '').replace(/\/\//g, '/'));
    });
}

export class NavigationBackButton extends Component {
    render() {
        return (
            <BackButton
                onClick={() => window.history.back()}
            >
            </BackButton>
        );
    }
}

export class NavItem extends Component {
    static propTypes = {
        to: PropTypes.string.isRequired,
        show: PropTypes.bool.isRequired,
        className: PropTypes.string.isRequired,
        activePath: PropTypes.string,
    };

    static defaultProps = {
        show: true,
        className: '',
    };

    stackVersion = null;

    constructor(...params) {
        super(...params);

        this.stackVersion = this.stackVersionFromUrl(window.location.href);
    }

    stackVersionFromUrl(url) {
        // Special case root which is v1.
        if (window.location.pathname === '/') {
            return 'v1';
        }

        return url.includes('#') ? 'v1' : 'v2';
    }

    /**
     * When in v1:
     *  - v1 links are "external"
     *  - v2 links are external
     *
     * When in v2:
     *  - v1 links are external
     *  - v2 links are internal
     */
    render() {
        const {to, show} = this.props;
        const toWithBaseV1 = baseUrl + to;
        const toWithBaseV2 = baseUrl + V2_URL_PREFIX + to;

        const linkStackVersion = this.stackVersionFromUrl(to);


        if (!show) {
            return null;
        }


        // V1 links should always be external, since Backbone.history does the routing.
        if (linkStackVersion === 'v1') {
            return (
                <NavItemExternal
                    {...omit(this.props, ...OMIT_PROPS)}
                    className={`nav-item ${this.props.className} ${checkActiveV1(this.props.activePath, to) ? 'active' : ''}`}
                    href={toWithBaseV1}
                />
            );
        }

        // If we are in v1, then v2 links should be external.
        if (this.stackVersion === 'v1') {
            return (
                <NavItemExternal
                    {...omit(this.props, ...OMIT_PROPS)}
                    className={`nav-item ${this.props.className} ${checkActiveV1(this.props.activePath, to) ? 'active' : ''}`}
                    href={toWithBaseV2}
                />
            );
        }

        // We're in v2 linking to a v2 page, no external link needed.
        return (
            <NavItemInternal
                {...omit(this.props, ...OMIT_PROPS)}
                className={`nav-item ${this.props.className} ${checkActiveV2(this.props.activePath, to) ? 'active' : ''}`}
                to={to}
            />
        );
    }
}
