import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Route as BaseRoute, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const CustomerOverview = Load(() => import('../screen/Customer/Overview'));
const CustomerDrawingStatic = Load(() => import('../screen/Customer/DrawingStatic'));
const CustomerDrawing = Load(() => import('../screen/Customer/Drawing'));
const Import = Load(() => import('../screen/Import'));

const LoginForgot = Load(() => import('spider/semantic-ui/LoginForgot'));
const ResetPassword = Load(() => import('spider/semantic-ui/ResetPassword'));
const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));


const NotFound = Load(() => import('../container/NotFound'));

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store}/>;
    };

    redirectFromHome = () => {
        const { currentUser } = this.props.store;
        let uri = currentUser.isSuperuser ? (
            '/assets/user/overview'
        ) : (
            '/nce13/customer/overview'
        );

        return <Redirect to={uri}/>;
    };

    render() {
        return (
            <Switch>
                <Route path="/" exact render={this.redirectFromHome}/>

                <Route path="/assets/user/overview" render={this.route(UserOverview)}/>
                <Route path="/assets/user/:id/edit" render={this.route(UserEdit)}/>
                <Route path="/assets/user/add" render={this.route(UserEdit)}/>
                <Route path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)}/>

                <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)}/>
                <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)}/>

                <Route path="/assets/global-file/overview" render={this.route(GlobalFileOverview)}/>
                <Route path="/assets/global-file/:id/edit" render={this.route(GlobalFileEdit)}/>

                <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)}/>

                <Route path="/account/details" render={this.route(AccountDetails)}/>
                <Route path="/account/changelog" render={this.route(Changelog)}/>
                <Route path="/login/forgot" render={this.route(LoginForgot)}/>
                <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)}/>

                <Route path="/nce13/customer/overview" render={this.route(CustomerOverview)}/>
                <Route path="/nce13/customer/static/drawing" render={this.route(CustomerDrawingStatic)}/>
                <Route path="/nce13/customer/:id/drawing" render={this.route(CustomerDrawing)}/>

                <Route path="/nce13/import" render={this.route(Import)}/>

                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
