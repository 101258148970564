import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

const StyledSvg = styled.svg`
    display: inline-block;
    fill: currentColor;
    height: ${props => props.height || 24}px;
    width: ${props => props.width || 24}px;
    user-select: none;
`;

function SvgIcon(props) {
    return <StyledSvg focusable="false" {...props} />;
}

SvgIcon.propTypes = {
    children: PropTypes.node,
    /**
   * Allows you to redefine what the coordinates without units mean inside an svg element.
   * For example, if the SVG element is 500 (width) by 200 (height),
   * and you pass viewBox="0 0 50 20",
   * this means that the coordinates inside the svg will go from the top left corner (0,0)
   * to bottom right (50,20) and each unit will be worth 10px.
   */
    viewBox: PropTypes.string,
};

SvgIcon.defaultProps = {
    viewBox: '0 0 24 24',
};

export { SvgIcon };

export const IconRestore = props => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 24 24">
            <path d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14zM6 7v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zm8 7v4h-4v-4H8l4-4 4 4h-2z"/>
            <path fill="none" d="M0 0h24v24H0z"/>
        </svg>
    </SvgIcon>
);