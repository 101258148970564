import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { COLOR_LIST } from './colors';

@observer
export default class PieChart_ extends Component {
    static propTypes = {
        data: PropTypes.number.isRequired,
        applyFilters: PropTypes.func.isRequired,
        format: PropTypes.func.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.onClick = this.onClick.bind(this);
    }

    @computed get data() {
        let { data } = this.props;
        data = Object.entries(data).map(([name, value]) => ({ name, value }));
        data.sort((l, r) => r.value - l.value);
        return data;
    }

    onClick({ name }) {
        const { applyFilters } = this.props;
        applyFilters(name);
    }

    render() {
        const { format } = this.props;

        const cells = [];
        for (let i = 0; i < this.data.length; i ++) {
            cells.push(<Cell key={i} fill={COLOR_LIST[i % COLOR_LIST.length]} />);
        }

        return (
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        dataKey="value"
                        nameKey="name"
                        data={this.data}
                        onClick={this.onClick}
                    >
                        {cells}
                    </Pie>
                    <Tooltip formatter={(value) => format(value)} />
                </PieChart>
            </ResponsiveContainer>
        );
    }
}
