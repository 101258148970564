export default {
    nav: {
        main: {
            assets: 'Master data',
            dcp: 'NCE13',
        },
        assets: {
            users: 'Users',
            globalValues: 'Settings',
            globalFiles: 'Special files',
        },
        audit: {
            accessLog: 'Access Log',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        dcp: {
            customers: 'Connected Parties',
            import: 'Data Import',
        },
    },
    user: {
        overview: {
            title: 'Users',
            addButton: 'Add User',
        },
        create: {
            title: 'Create User',
        },
        edit: {
            title: 'Edit User',
        },
        field: {
            fullName: { label: 'Name' },
            firstName: { label: 'First Name' },
            lastName: { label: 'Last Name' },
            email: { label: 'Email' },
            password: { label: 'Password' },
            dateJoined: { label: 'Added At' },
            groups: {
                label: 'Groups',
                value: {
                    superuser: 'Superuser',
                    admin: 'Admin',
                    manager: 'Manager',
                    customer: 'Connected Party',
                },
                empty: 'No groups assigned',
            },
            customer: { label: 'Connected Party' },
            language: {
                label: 'Language'
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not Active',
                }
            },
        },
        login: {
            title: 'Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send Email',
            requestedEmailText:
                'An email with a reset code has been sent to this email address.',
            loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
        },
        changePassword: {
            title: 'Change Password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log Out',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Change Password',
            },
            logout: {
                title: 'Log Out',
                confirm: 'Are you sure you want to logout?'
            },
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },
    globalValue: {
        edit: {
            title: 'Edit value of global setting',
        },
        overview: {
            title: 'Global settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'Name for file',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Original user',
            },
            masqueradingAsUser: {
                label: 'Masquerading as user',
            },
            ipAddress: {
                label: 'IP address',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        delete: 'Delete',
        view: 'View',
        restore:'Restore',
    },
    form: {
        repeat: 'Repeat',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'No file uploaded yet',
        },
        saveButton: 'Save',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
        },
        submitButton: 'Submit',
        cancelButton: 'Cancel',
        addButton: 'Add',
        startDate: 'Start Date',
        endDate: 'End Date',
        deleteConfirmation: 'Are you sure you want to delete this entity?',
        restoreConfirmation: 'Are you sure you want to restore this entity?'
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: 'We’re very sorry, but the application has crashed.',
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Page not found',
    brandingMessage: 'Powered by Code Yellow',
    customer: {
        overview: {
            title: 'Connected Parties',
            customer: 'Connected Party',
        },
        create: {
            title: 'Create Connected Party',
        },
        edit: {
            title: 'Edit Connected Party',
        },
        drawing: {
            todo: '{{count}} TODO',
            needs: {
                E: 'E (Essential) questions are the minimally required questions to be answered.',
                NE: 'NE (Non Essential) questions are not required, but allow TenneT to improve the quality of the data model of your facility.',
                FI: 'FI (For Information) questions are not required, but provide TenneT more context about your facility.',
            },
            object: {
                facility: {
                    title: 'Facility',
                    header: 'Facility',
                    field: {
                        name: { label: 'Name' },
                        tagName: { label: 'Tag Name' },
                        latitude: { label: 'Latitude' },
                        longitude: { label: 'Longitude' },
                        inServiceDate: { label: 'In Service Date' },
                        inServiceDateKind: { label: 'In Service Date Kind' },
                        outOfServiceDate: { label: 'Out of Service Date' },
                        outOfServiceDateKind: { label: 'Out of Service Date Kind' },
                        technicalStatus: { label: 'Technical Status' },
                        singleLineDiagram: { label: 'Single Line Diagram' },
                        area: { label: 'Area' },
                        geographicalLocation: { label: 'Geographical Location' },
                        ownerKind: { label: 'Owner Kind' },
                        subGeographicalRegion: { label: 'Subgeographical Region' },
                        zone: { label: 'Zone' },
                    },
                },
                transformer: {
                    title: 'Transformer 2W',
                    header: 'Transformer 2W {{n}}',
                    field: {
                        connectionSymbol: { label: 'Connection Symbol' },
                        name: { label: 'Name' },
                        hvNeutralEarthing: { label: 'HV Neutral Earthing' },
                        lvNeutralEarthing: { label: 'LV Neutral Earthing' },
                        magnetisingCurrent: { label: 'Magnetising Current' },
                        hvNeutralSwitch: { label: 'HV Neutral Switch' },
                        lvNeutralSwitch: { label: 'LV Neutral Switch' },
                        hvXe: { label: 'HV Neutral Earthing Reactance' },
                        lvXe: { label: 'LV Neutral Earthing Reactance' },
                        fatReport: { label: 'FAT Report' },
                        vendorDataSheet: { label: 'Vendor Data Sheet' },
                        hvRe: { label: 'HV Neutral Earthing Resistance' },
                        lvRe: { label: 'LV Neutral Earthing Resistance' },
                        saturationCurve: { label: 'Saturation Curve' },
                        p0: { label: 'No Load Loss' },
                        tank: { label: 'Tank' },
                        posToleranceUCC: { label: 'Pos. Tolerance ucc' },
                        negToleranceUCC: { label: 'Neg. Tolerance ucc' },
                        umax: { label: 'Maximum Operating Voltage Fundamental' },
                        umaxDC: { label: 'Maximum Operating Voltage DC' },
                        umax150Hz: { label: 'Maximum Operating Voltage 150Hz' },
                        coolingLow: { label: 'Cooling Low' },
                        coolingHigh: { label: 'Cooling High' },
                        pk: { label: 'Load Loss' },
                        s: { label: 'Rated Apparant Power' },
                        sFC: { label: 'Rated Apparant Power Forced Cooling' },
                        hvRatedVoltage: { label: 'HV Rated Voltage' },
                        lvRatedVoltage: { label: 'LV Rated Voltage' },
                        sRef: { label: 'Reference Power' },
                        uRef: { label: 'Reference Voltage' },
                        ucc: { label: 'Short Circuit Impedence' },
                    },
                },
                load_demand: {
                    title: 'Load Demand',
                    header: 'Load Demand {{n}}',
                    field: {
                        name: { label: 'Name' },
                        maxP: { label: 'Max Active Power' },
                        maxPPct: { label: 'Max Active Power %' },
                        modeQ: { label: 'Reactive Power Mode' },
                        maxQ: { label: 'Max Reactive Power' },
                        maxQPct: { label: 'Max Reactive Power %' },
                        pf: { label: 'Power Factor' },
                    },
                },
                double_bus: {
                    title: 'Double Bus',
                    header: 'Double Bus {{n}}',
                    field: {
                        bayName: { label: 'Bay Name' },
                        busSectionName: { label: 'Bus Section Name' },
                        nominalVoltage: { label: 'Nominal Voltage' },
                        minOperationalVoltageLimit: { label: 'Minimal Operational Voltage Limit' },
                        maxOperationalVoltageLimit: { label: 'Maximal Operational Voltage Limit' },
                    },
                },
                capacator_bank: {
                    title: 'Capacator Bank',
                    header: 'Capacator Bank {{n}}',
                    field: {
                    },
                },
                cable_segment: {
                    title: 'Cable Segment',
                    header: 'Cable Segment {{n}}',
                    field: {
                        crossSectionArea: { label: 'Cable Cross Section' },
                        diameter: { label: 'Diameter' },
                        trenchCrossSection: { label: 'Trench Cross Section' },
                        conductorConstruction: { label: 'Conductor Construction' },
                        insulation: { label: 'Insulation' },
                        vendorDataSheet: { label: 'Vendor Data Sheet' },
                        earthingSchematic: { label: 'Earthing Schematic' },
                        svlLocationSchematic: { label: 'SVL Location Schematic' },
                        phaseSequenceSchematic: { label: 'Phase Sequence Schematic' },
                        crossBondingSchematic: { label: 'Cross Bonding Schematic' },
                        svlChargeRatings: { label: 'SVL Charge Ratings' },
                        svlDataSheet: { label: 'SVL Data Sheet' },
                        svlEnergyRatings: { label: 'SVL Energy Ratings' },
                        svlRatedVoltage: { label: 'SVL Rated Voltage' },
                        svlResidualVoltage: { label: 'SVL Residual Voltage' },
                        svlConnectionSchematic: { label: 'SVL Connection Schematic' },
                        conductorCrossSectionArea: { label: 'Conductor Cross Section' },
                        conductorMaterial: { label: 'Conductor Material' },
                        conductorsPerPhase: { label: 'Conductors per Phase' },
                        impedenceMeasurement: { label: 'Impedence Measurement' },
                        length: { label: 'Length' },
                        maxTemperatureAtEndOfFault: { label: 'Max Temperature at End of Fault' },
                        name: { label: 'Name' },
                        nominalCurrent: { label: 'Nominal Current' },
                        c1: { label : 'Positive Sequence Capacitance per km' },
                        x1: { label : 'Positive Sequence Reactance per km' },
                        r1: { label : 'Positive Sequence Resistance per km' },
                        ratedVoltage: { label : 'Rated Voltage' },
                        resistanceRefTemp: { label : 'Resistance Ref. Temp.' },
                        resistanceKind: { label : 'Resistance Kind' },
                        sequenceNumber: { label : 'Sequence No.' },
                        sheathEarthing: { label : 'Sheath Earthing' },
                        terminalWithstandCurrent: { label : 'Terminal Withstand Current' },
                        terminalWithstandDuration: { label : 'Terminal Withstand Duration' },
                        typeDesignation: { label : 'Type Designation' },
                        earthResistivity: { label : 'Earth Resistivity' },
                        c0: { label : 'Zero Sequence Capacitance per km' },
                        x0: { label : 'Zero Sequence Reactance per km' },
                        r0: { label : 'Zero Sequence Resistance per km' },
                    },
                },
                load_demand_group: {
                    title: 'Load Demand Group',
                    header: 'Load Demand Group {{n}}',
                    field: {
                        name: { label: 'Name' },
                        maxP: { label: 'Max Active Power' },
                        modeQ: { label: 'Reactive Power Mode' },
                        maxQ: { label: 'Max Reactive Power' },
                        pf: { label: 'Power Factor' },
                    },
                },
                load_demand_with_group: {
                    header: 'Load Demand {{n}}',
                    field: {
                        maxPPct: { label: 'Max Active Power %' },
                        maxQPct: { label: 'Max Reactive Power %' },
                    },
                },
            },
        },
        field: {
            name: { label: 'Name' },
            status: { label: 'Operational Notification' },
            owner: { label: 'Owner Kind' },
            lastReconfirmationDate: { label: 'Last Reconfirmation Date' },
            terminationDate: { label: 'Termination Date' },
            facilityStatus: {
                label: 'Facility Status',
                value: {
                    planned: 'Planned',
                    under_construction: 'Under Construction',
                    in_commissioning: 'In Commissioning',
                    in_operation: 'In Operation',
                    not_in_operation: 'Not in Operation',
                    under_construction: 'Under Reconstruction',
                    mothballed: 'Mothballed',
                    dismantled: 'Dismantled',
                },
            },
        },
    },
};
