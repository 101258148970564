import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Link,
    Button,
    IconSave,
    IconSend,
    IconCancel,
    IconRefresh,
    IconSearch,
    IconNavigateBefore,
    IconVisibility,
    IconEdit,
    IconDelete,
    IconAddCircle,
    IconContentCopy,
    IconGetApp,
    IconVisibilityOff,
    IconDone,
    IconDeleteForever,
    ExternalLink,
} from 're-cy-cle';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { IconRestore } from './component/Icon';

const StyledIcon = styled(Icon)`
    cursor: pointer;
    margin: 4px !important;
    line-height: normal;
`;

const StyledA = styled.a`
    color:  #000;
    &:visited {
        color:#000;
    }
    &:hover {
        color:#000;
    }
    &:active {
        color:#000;
    }
`;

/**
 * Use Semantic-UI icons as buttons. It also support simple titles which will
 * turn into popups.
 */
export class IconButton extends Component {
    static propTypes = {
        title: PropTypes.string,
        href: PropTypes.string,
    };

    render() {
        const { title, href, ...props } = this.props;
        let buttonIcon = <StyledIcon {...props} />;

        if (href) {
            buttonIcon = <StyledA href={href} data-test-anchor>{buttonIcon}</StyledA>;
        }

        if (title) {
            return <Popup style={{ marginLeft: '-0.75em' }} trigger={buttonIcon} content={title} />
        }

        return buttonIcon;
    }
}

const StyledButton = styled(Button)`
    ${props => props.form && `
        margin-top: 19px;
    `}

    ${props => !props.icon && `
        // So it's more visible that it is a button when the background is light.
        border: 1px solid #ccc;
    `}
`;

const StyledLink = styled(Link)`
    ${props => props.form && `
        margin-top: 19px;
    `}
`;

export class SaveButton extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        children: PropTypes.node,
        className: PropTypes.string,
        form: PropTypes.bool,
    };

    render() {
        const { children, loading, disabled, className, ...props } = this.props;

        return (
            <StyledButton
                disabled={disabled || loading}
                className={className}
                {...props}
            >
                <IconSave />
                {children || t('form.saveButton')}
            </StyledButton>
        );
    }
}

export class DownloadButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        href: PropTypes.string,
        children: PropTypes.node,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string,
    };

    render() {
        const { children, icon, href, loading, disabled, className } = this.props;
        const C = href ? ExternalLink: StyledButton;

        return (
            <C
                disabled={disabled || loading}
                className={className}
                {...this.props}
            >
                <IconGetApp />
                {children || (!icon && t('form.downloadButton'))}
            </C>
        );
    }
}

export class SearchModalButton extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        children: PropTypes.node,
        className: PropTypes.string,
        form: PropTypes.bool,
        icon: PropTypes.bool,
    };

    render() {
        const { children, loading, disabled, className, ...props } = this.props;

        return (
            <StyledButton
                disabled={disabled || loading}
                className={className}
                {...props}
            >
                <IconSearch />
                {children || (!this.props.icon && t('form.searchModalButton'))}
            </StyledButton>
        );
    }
}

export class RefreshButton extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        children: PropTypes.node,
        className: PropTypes.string,
        form: PropTypes.bool,
    };

    render() {
        const { children, loading, disabled, className, ...props } = this.props;

        return (
            <StyledButton
                disabled={disabled || loading}
                className={className}
                {...props}
            >
                <IconRefresh />
                {children || t('form.refreshButton')}
            </StyledButton>
        );
    }
}

export class ReExportButton extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        children: PropTypes.node,
        className: PropTypes.string,
        form: PropTypes.bool,
        to: PropTypes.string,
        icon: PropTypes.bool,
    };

    render() {
        const { children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                {...this.props}
            >
                <IconRefresh />
                {children || (!icon && t('form.reExportButton'))}
            </C>
        );
    }
}

export class BackButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string,
    };

    render() {
        const { children, icon, to, loading, disabled, className } = this.props;
        const C = to ? StyledLink: StyledButton;

        return (
            <C
                disabled={disabled || loading}
                className={className}
                {...this.props}
            >
                <IconNavigateBefore />
                {children || (!icon && t('form.backButton'))}
            </C>
        );
    }
}

export class EditButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                {...this.props}
            >
                <IconEdit />
                {children || (!icon && t('form.editButton'))}
            </C>
        );
    }
}


export class ViewButton extends Component {
    static propTypes = {
        show: PropTypes.bool,
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { show, children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                {...this.props}
            >
                {!show ? <IconVisibility /> : <IconVisibilityOff />}
                {children || (!icon && t('form.viewButton'))}
            </C>
        );
    }
}

export class HideButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                {...this.props}
            >
                <IconVisibilityOff />
                {children || (!icon && t('form.viewButton'))}
            </C>
        );
    }
}

export class RestoreButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                {...this.props}
            >
                <IconRestore />
                {children || (!icon && t('form.restoreButton'))}
            </C>
        );
    }
}

export class CancelButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                tone="light"
                {...this.props}
            >
                <IconCancel />
                {children || (!icon && t('form.cancelButton'))}
            </C>
        );
    }
}

export class DeleteButton extends Component {
    static propTypes = {
        children: PropTypes.node,
        icon: PropTypes.bool,
    };

    render() {
        const { children, icon } = this.props;

        return (
            <StyledButton
                {...this.props}
            >
                <IconDelete />
                {children || (!icon && t('form.deleteButton'))}
            </StyledButton>
        );
    }
}

export class HardDeleteButton extends Component {
    static propTypes = {
        children: PropTypes.node,
        icon: PropTypes.bool,
    };

    render() {
        const { children, icon } = this.props;

        return (
            <StyledButton
                {...this.props}
            >
                <IconDeleteForever />
                {children || (!icon && t('form.deleteButton'))}
            </StyledButton>
        );
    }
}

export class AddButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                {...this.props}
            >
                <IconAddCircle />
                {children || (!icon && t('form.addButton'))}
            </C>
        );
    }
}

export class CopyButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                {...this.props}
            >
                <IconContentCopy />
                {children || (!icon && t('form.copyButton'))}
            </C>
        );
    }
}

export class SendButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                {...this.props}
            >
                <IconSend />
                {children || (!icon && t('form.sendButton'))}
            </C>
        );
    }
}

/**
 * Renders either a ViewButton or an EditButton, depending on
 * model.isImmutable.
 */
export class EditImmutableButton extends Component {
    static propTypes = {
        model: PropTypes.object.isRequired,
    };

    render() {
        const { model } = this.props;

        if (model.isImmutable) {
            return <ViewButton {...this.props} />;
        }

        return <EditButton {...this.props} />;
    }
}

export class ApplyButton extends Component {
    static propTypes = {
        icon: PropTypes.bool,
        to: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { children, icon, to } = this.props;
        const C = to ? StyledLink : StyledButton;

        return (
            <C
                {...this.props}
            >
                <IconDone />
                {children || (!icon && t('form.applyButton'))}
            </C>
        );
    }
}
