import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

const SplitContainer = styled.div`
    display: flex;
    ${({ direction }) => direction === 'vertical' ? css`
        flex-direction: column;
    ` : css`
        flex-direction: row;
    `}
    align-items: stretch;
`;

const SplitItem = styled.div`
    flex: 1 1 ${({ percentage }) => percentage}%;
    min-width: 0;
    min-height: 0;
`;

const DragHandle = styled.div`
    display: flex;
    justify-content: center;
    &:after {
        content: '';
        background-color: #DEDEDE;
        flex: 0 0 1px;
    }
    ${({ direction }) => direction === 'vertical' ? css`
        cursor: row-resize;
        flex-direction: column;
        margin: 0 -12.5px;
        padding: 12px 0;
    ` : css`
        cursor: col-resize;
        flex-direction: row;
        margin: -12.5px 0;
        padding: 0 12px;
    `}
`;

const SNAPS = [0.25, 0.3333, 0.50, 0.6667, 0.75];
const SNAP_DIST = 20;

@observer
export default class Split extends Component {
    static propTypes = {
        direction: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
        first: PropTypes.node.isRequired,
        second: PropTypes.node.isRequired,
        storagePrefix: PropTypes.string,
    };

    static defaultProps = {
        storagePrefix: null,
    };

    constructor(...args) {
        super(...args);
        this.rootRef = this.rootRef.bind(this);
        this.dragStart = this.dragStart.bind(this);
        this.dragMove = this.dragMove.bind(this);
        this.dragEnd = this.dragEnd.bind(this);
    }

    componentDidMount() {
        window.addEventListener('mousemove', this.dragMove);
        window.addEventListener('mouseup', this.dragEnd);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.dragMove);
        window.removeEventListener('mouseup', this.dragEnd);
    }

    @computed get storageKey() {
        const { storagePrefix, direction } = this.props;
        return storagePrefix && storagePrefix + direction;
    }

    @observable baseFirstPercentage = null;

    @computed get firstPercentage() {
        if (this.baseFirstPercentage !== null) {
            return this.baseFirstPercentage;
        }
        const value = this.storageKey && localStorage.getItem(this.storageKey);
        if (value === null) {
            return 50;
        } else {
            return JSON.parse(value);
        }
    }

    set firstPercentage(value) {
        this.baseFirstPercentage = value;
        if (this.storageKey) {
            localStorage.setItem(this.storageKey, JSON.stringify(value));
        }
    }

    rootNode = null;
    drag = null;

    rootRef(node) {
        this.rootNode = node;
    }

    getNodes() {
        return [this.rootNode.childNodes[0], this.rootNode.childNodes[1], this.rootNode.childNodes[2]];
    }

    dragStart(e) {
        if (e.button !== 0) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        const { direction } = this.props;
        const rootRect = this.rootNode.getBoundingClientRect();
        let valueField, sizeField, base, size;
        if (direction === 'vertical') {
            valueField = 'clientY';
            sizeField = 'clientHeight';
            base = rootRect.top;
            size = rootRect.height;
        } else {
            valueField = 'clientX';
            sizeField = 'clientWidth';
            base = rootRect.left;
            size = rootRect.width;
        }

        const handle = this.rootNode.childNodes[1][sizeField];
        base += handle / 2;
        size -= handle;

        this.drag = { direction, valueField, sizeField, base, size, offset: e[valueField] - base - size * (this.firstPercentage / 100) };

        const [firstNode, , secondNode] = this.getNodes();

        firstNode.style.transformOrigin = direction === 'vertical' ? 'top' : 'left';
        secondNode.style.transformOrigin = direction === 'vertical' ? 'bottom' : 'right';

        this.dragMove(e);
    }

    dragMove(e) {
        if (this.drag === null) {
            return;
        }


        const { direction, valueField, sizeField, base, size, offset } = this.drag;
        let progress = (e[valueField] - offset - base) / size;
        for (const snap of SNAPS) {
            if (Math.abs((size * progress + base) - (size * snap + base)) < SNAP_DIST) {
                progress = snap;
                break;
            }
        }

        const axis = direction == 'vertical' ? 'Y' : 'X';

        const [firstNode, lineNode, secondNode] = this.getNodes();

        firstNode.style.transform = '';
        firstNode.style.transform = `scale${axis}(${(size * progress) / firstNode[sizeField]})`;

        lineNode.style.transform = `translate${axis}(${(base + size * progress) - (base + size * (this.firstPercentage / 100))}px)`;

        secondNode.style.transform = '';
        secondNode.style.transform = `scale${axis}(${(size * (1 - progress)) / secondNode[sizeField]})`;

        return progress;
    }

    @action dragEnd(e) {
        if (e.button !== 0 || this.drag === null) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        this.firstPercentage = this.dragMove(e) * 100;
        this.drag = null;

        const [firstNode, lineNode, secondNode] = this.getNodes();

        firstNode.style.transform = '';
        firstNode.style.transformOrigin = '';

        lineNode.style.transform = '';

        secondNode.style.transform = '';
        secondNode.style.transformOrigin = '';
    }

    render() {
        const { direction, first, second } = this.props;
        return (
            <SplitContainer innerRef={this.rootRef} key={direction} direction={direction}>
                <SplitItem percentage={this.firstPercentage}>{first}</SplitItem>
                <DragHandle direction={direction} onMouseDown={this.dragStart} />
                <SplitItem percentage={100 - this.firstPercentage}>{second}</SplitItem>
            </SplitContainer>
        );
    }
}
