import styled from 'styled-components';

export default styled.div`
    margin-left: ${props => props.offset ? props.offset : 'auto'};
    width: 0px;
`;

export const BottomDivider = styled.div`
    margin-top: auto;
    height: 0px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;
