import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { uniqueId, some } from 'lodash';
import styled from 'styled-components';

let viewStore = null;
const TooltipContainer = styled.div`
    ${props => props.emphasize && `
        background-color: ${props.theme.buttonPrimaryColor};
    `}
        display: inline flex;
`;

export function configurePermission(givenViewStore) {
    viewStore = givenViewStore;
}

export function hasLowLevelPermission(...args) {
    if (viewStore.currentUser.hasLowLevelPermission) {
        return viewStore.currentUser.hasLowLevelPermission(...args);
    }

    // Fallback for projects who don't have hasLowLevelPermission.
    return true;
}

/**
 * Example usage:
 *
 *  - Single permission, hide when user doesn't have permission.
 *
 *  <Permission permission="export.change_filebundle" action="hide">
 *      <SaveButton loading={this.fileBundle.isLoading} onClick={this.save} />
 *  </Permission>
 *
 *  - Multi permission (true if user has 1 permission), hide when user doesn't have permission.
 *
 *  <Permission permission={['allocation.modify_team', 'allocation.modify_team:branch']} action="describe" field="team.branch">
 *      <FormSelectInput />
 *  </Permission>
 *
 */
export default class Permission extends Component {
    static propTypes = {
        permission: PropTypes.any.isRequired,
        action: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
        field: PropTypes.string.isRequired,
    };

    static defaultProps = {
        action: 'hide',
    };

    checkPermission = () => {
        const { permission } = this.props;

        if (Array.isArray(permission)) {
            return some(permission, (...p) => hasLowLevelPermission(...p));
        }

        return hasLowLevelPermission(permission);
    }

    constructor(props) {
        super(props);

        this.id = uniqueId('tip-');
        this.showDescription = !!localStorage.getItem('permission.showDescription');
    }

    render() {
        const { action, children } = this.props;
        const extraProps = {};

        if (!this.checkPermission()) {
            if (action === 'hide') {
                return null;
            }

            if (action === 'disable') {
                extraProps.disabled = true;
            }
        }

        // const decoratedChildren = React.cloneElement(
        //     children,
        //     extraProps,
        // );

        if(!this.showDescription){
            return children;
        }

        // TODO: change children to decoratedChildren which sometimes fails for whatever reason.
        return (
            <React.Fragment>
                <TooltipContainer data-tip data-for={this.id} emphasize={this.showDescription}>
                    {children}
                </TooltipContainer>
                {this.renderDescription()}
            </React.Fragment>
        );
    }

    renderDescription = () => {
        const { permission, field } = this.props;
        const permissions = !Array.isArray(permission) ? [permission] : permission;

        const model = field ? field.split('.')[0] : '';
        const field2 = field ? field.split('.')[1] : '';

        return (
            <ReactTooltip id={this.id}>
                {permissions.map(p => {
                    let description = t(`${model}.field.${field2}.permission.${p}`);

                    if (!field) {
                        description = t(`permission.field.codename.value.${p}`);
                    }

                    return (
                        <div><strong>{p}:</strong> {description}</div>
                    );
                })}
            </ReactTooltip>
        );
    }
}
