import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, runInAction, computed } from 'mobx';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { Dimmer, Icon } from 'semantic-ui-react';
import { snakeToCamel } from 'helpers';
import { theme } from 'styles';
import Number from './Number';
import PieChart from './PieChart';
import BarChart from './BarChart';
import Timeline from './Timeline';

const DashboardContainer = styled.div`
    display: grid;
    grid-template: repeat(${({ height }) => height}, 1fr) / repeat(${({ width }) => width}, 1fr);
    gap: 12px;
    height: 100%;
`;

const TileContainer = styled.div`
    grid-area: ${({ y }) => y + 1} / ${({ x }) => x + 1} / span ${({ height }) => height} / span ${({ width }) => width};
    background-color: #FFF;
    ${({ filtersActive }) => filtersActive ? `
        border-radius: calc(0.28571429rem + 4px);
        border: 3px solid ${theme.primaryColor};
        margin: -2px;
    ` : `
        border-radius: 0.28571429rem;
        border: 1px solid rgba(34, 36, 38, 0.15);
    `};
    box-shadow: 0 1px 3px 0 #d4d4d5;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    ${({ hasFilters }) => hasFilters && css`
        .number-container, .recharts-pie-sector, .recharts-bar-rectangles {
            cursor: pointer !important;
        }
    `}
`;

const TileLabel = styled.div`
    font-weight: bold;
    font-size: 1.5rem;
    padding: 1rem;
`;

const TileContent = styled.div`
    height: 100%;
    flex: 1 1 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ResetIcon = styled(Icon)`
    float: right;
    margin: 0 !important;
    color: ${theme.primaryColor};
    ${({ active }) => active ? `
        cursor: pointer;
    ` : `
        opacity: 0 !important;
    `}
`;

@observer
export default class Dashboard extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        fetch: PropTypes.func.isRequired,
        dashboard: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired,
        applyFilters: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        loading: false,
    };

    @computed get positionedStats() {
        const { dashboard: { width, height, stats } } = this.props;

        // init an empty grid
        const grid = [];
        for (let i = 0; i < width * height; i++) {
            grid.push(null);
        }

        // a function that tries to place a statistic on this grid
        function placeStat(x, y, index) {
            const { width: statWidth = 1, height: statHeight = 1 } = stats[index];

            const cells = [];
            for (let x_ = x; x_ < x + statWidth; x_++) {
                for (let y_ = y; y_ < y + statHeight; y_++) {
                    cells.push(x_ + y_ * width);
                }
            }

            for (const cell of cells) {
                if (grid[cell] !== null) {
                    return false;
                }
            }
            for (const cell of cells) {
                grid[cell] = index;
            }
            return true;
        }

        // First add all stats that have a position
        const unpositioned = [];
        for (let index = 0; index < stats.length; index++) {
            const { x, y } = stats[index];
            if (x == undefined || y === undefined) {
                unpositioned.push(index);
            } else if (!placeStat(x, y, index)) {
                throw new Error(`could not place stat ${index} at ${x},${y}`)
            }
        }

        // Then try to find a place for all unpositioned
        for (const index of unpositioned) {
            const { width: statWidth = 1, height: statHeight = 1 } = stats[index];

            let placed = false;
            for (let y = 0; !placed && y <= height - statHeight; y++) {
                for (let x = 0; !placed && x <= width - statWidth; x++) {
                    placed = placeStat(x, y, index);
                }
            }

            if (!placed) {
                throw new Error(`could not place stat ${index}`);
            }
        }

        // Convert this grid to a list
        const positionedStats = [];
        const positionedIndexes = {};

        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                const index = grid[x + y * width];
                if (index !== null && !positionedIndexes[index]) {
                    positionedStats.push({ ...stats[index], x, y });
                    positionedIndexes[index] = true;
                }
            }
        }

        return positionedStats;
    }

    render() {
        const { store, fetch, dashboard: { width, height }, data, applyFilters, loading } = this.props;

        return (
            <DashboardContainer width={width} height={height}>
                {this.positionedStats.map((stat) => (
                    <Tile
                        key={`${stat.x},${stat.y}`}
                        stat={stat}
                        data={data?.[stat.stat] ?? null}
                        applyFilters={applyFilters}
                        loading={loading}
                        store={store}
                        fetch={fetch}
                    />
                ))}
            </DashboardContainer>
        );
    }
}

function addThousands(value) {
    if (typeof value !== 'number') {
        return value;
    }

    value = value.toString();
    let maxIndex = value.indexOf(',');
    if (maxIndex === -1) {
        maxIndex = value.length;
    }

    const parts = [];
    let i = maxIndex % 3;
    if (i !== 0) {
        parts.push(value.slice(0, i));
    }
    while (i < maxIndex) {
        parts.push(value.slice(i, i + 3));
        i += 3;
    }

    if (maxIndex !== value.length) {
        parts.push(value.slice(maxIndex));
    }

    return parts.join('.');
}

const CONTENT_TYPES = {
    number: Number,
    pie_chart: PieChart,
    bar_chart: BarChart,
    timeline: Timeline,
};

@observer
class Tile extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        fetch: PropTypes.func.isRequired,
        stat: PropTypes.object.isRequired,
        data: PropTypes.any.isRequired,
        applyFilters: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.applyFilters = this.applyFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    @computed get groupFilter() {
        let { stat: { type, filter_key }, data: { group_by } } = this.props;
        if (filter_key !== undefined) {
            return filter_key;
        } else if (type === 'timeline') {
            return `.${group_by}:range`;
        } else {
            return `.${group_by}:in`;
        }
    }

    @computed get hasFilters() {
        const { data } = this.props;
        return data !== null && (data.group_by !== undefined || Object.keys(data.filters).length > 0);
    }

    @computed get filtersActive() {
        if (!this.hasFilters) {
            return false;
        }
        const { store, data: { group_by, filters } } = this.props;
        for (const [key, value] of Object.entries(filters)) {
            if (store.params[`.${key}`] !== value) {
                return false;
            }
        }
        return !group_by || store.params[this.groupFilter] !== undefined;
    }

    async applyFilters(value = null) {
        if (!this.hasFilters) {
            return;
        }
        const { store, fetch, stat: { filter_value }, data: { group_by, filters } } = this.props;
        if (group_by && filter_value) {
            value = await Promise.resolve(filter_value(value));
        }
        runInAction(() => {
            for (const [key, value] of Object.entries(filters)) {
                store.params[`.${key}`] = value;
            }
            if (group_by) {
                store.params[this.groupFilter] = value;
            }
            fetch();
        });
    }

    @action resetFilters() {
        if (!this.filtersActive) {
            return;
        }
        const { store, fetch, data: { group_by, filters } } = this.props;
        for (const key of Object.keys(filters)) {
            delete store.params[`.${key}`];
        }
        if (group_by) {
            delete store.params[this.groupFilter];
        }
        fetch();
    }

    @computed get label() {
        const { store, stat: { label, stat } } = this.props;
        return label ?? t(`${snakeToCamel(store.constructor.backendResourceName)}.overview.stat.${stat}`)
    }

    render() {
        const { store, stat: { x, y, width = 1, height = 1, type, format = addThousands }, data, loading } = this.props
        const Content = CONTENT_TYPES[type];
        return (
            <TileContainer x={x} y={y} width={width} height={height} hasFilters={this.hasFilters} filtersActive={this.filtersActive}>
                <TileLabel>
                    {this.label}
                    <ResetIcon name="undo" active={this.filtersActive} onClick={this.resetFilters} />
                </TileLabel>
                <TileContent>
                    {data !== null && (
                        <Content
                            data={data.value}
                            format={format}
                            applyFilters={this.applyFilters}
                            filtered={data.group_by ? (store.params[this.groupFilter] ?? null) : null}
                        />
                    )}
                    <Dimmer inverted active={loading} />
                </TileContent>
            </TileContainer>
        );
    }
}
