import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Container = styled.span`
    font-size: 5rem;
    font-weight: bold;
`;

@observer
export default class Number extends Component {
    static propTypes = {
        data: PropTypes.number.isRequired,
        applyFilters: PropTypes.func.isRequired,
        format: PropTypes.func.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.ref = this.ref.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    resizeObserver = new ResizeObserver(() => this.checkSize());
    node = null;

    ref(node) {
        this.resizeObserver.disconnect();
        this.node = node;
        if (node !== null) {
            this.checkSize();
            this.resizeObserver.observe(node);
            this.resizeObserver.observe(node.parentNode);
        }
    }

    checkSize() {
        this.node.style.transform = '';

        const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

        const textWidth = this.node.clientWidth;
        const tileWidth = this.node.parentNode.clientWidth - 2 * rem;

        const textHeight = this.node.clientHeight;
        const tileHeight = this.node.parentNode.clientHeight - 2 * rem;

        const scale = Math.min(tileWidth / textWidth, tileHeight / textHeight);

        if (scale < 1) {
            this.node.style.transform = `scale(${scale})`;
        }
    }

    onClick() {
        const { applyFilters } = this.props;
        applyFilters();
    }

    render() {
        const { data, format } = this.props;
        const text = format(data);
        return (
            <Container
                className="number-container"
                innerRef={this.ref}
                onClick={this.onClick}
            >
                {text}
            </Container>
        );
    }
}
