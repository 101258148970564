import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { AreaChart, Area, Tooltip, Customized, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { SERVER_DATE_FORMAT, DATE_FORMAT } from 'helpers';
import { theme } from 'styles';

const Selection = styled.rect`
    position: absolute;
    fill: #E0E0E0;
    y: 0;
    height: 100%;
    opacity: 0;
`;

@observer
export default class Timeline extends Component {
    static propTypes = {
        data: PropTypes.number.isRequired,
        applyFilters: PropTypes.func.isRequired,
        format: PropTypes.func.isRequired,
        filtered: PropTypes.string,
    };

    constructor(...args) {
        super(...args);
        this.selectionRef = this.selectionRef.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
    }

    componentDidMount() {
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.onMouseUp);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
    }

    @computed get range() {
        const { filtered, data } = this.props;

        if (filtered) {
            const [minDate, maxDate] = filtered.split(',');
            return [moment(minDate, SERVER_DATE_FORMAT), moment(maxDate, SERVER_DATE_FORMAT)];
        }

        const dates = Object.keys(data);
        if (dates.length === 0) {
            return null;
        }

        let minDate = dates[0];
        let maxDate = dates[0];
        for (const key of dates) {
            if (key < minDate) {
                minDate = key;
            }
            if (key > maxDate) {
                maxDate = key;
            }
        }
        return [moment(minDate, SERVER_DATE_FORMAT), moment(maxDate, SERVER_DATE_FORMAT)];

    }

    @computed get data() {
        if (this.range === null) {
            return [];
        }

        const { data } = this.props;
        const [minDate, maxDate] = this.range;
        const timeline = [];

        let date = moment(minDate, SERVER_DATE_FORMAT);
        const endDate = moment(maxDate, SERVER_DATE_FORMAT);
        while (date.isSameOrBefore(endDate)) {
            const text = date.format(DATE_FORMAT);
            timeline.push({ date, text, value: data[date.format(SERVER_DATE_FORMAT)] ?? 0 });
            date = date.clone().add(1, 'd');
        }

        return timeline;
    }

    selectionRef(node) {
        this.selectionNode = node;
    }

    selectionNode = null;

    drag = null;

    getIndex(e) {
        const { target } = this.drag;
        const { x, width } = target.getBoundingClientRect();
        const progress = Math.min(Math.max((e.clientX - x) / width, 0), 1);
        return Math.round(progress * (this.data.length - 1));
    }

    onMouseDown(_, e) {
        if (e === undefined || e.button !== 0) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        this.drag = { target: e.currentTarget };
        this.drag.start = this.getIndex(e);
        this.selectionNode.style.opacity = '1';
        this.onMouseMove(e);
    }

    onMouseMove(e) {
        if (this.drag === null) {
            return;
        }

        const { start } = this.drag;
        const curr = this.getIndex(e);
        this.selectionNode.style.x = `${Math.min(start, curr) / (this.data.length - 1) * 100}%`;
        this.selectionNode.style.width = `${Math.abs(curr - start) / (this.data.length - 1) * 100}%`;
    }

    onMouseUp(e) {
        if (this.drag === null || e.button !== 0) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        const { start } = this.drag;
        const end = this.getIndex(e);

        this.selectionNode.style.opacity = '0';
        this.drag = null;

        const { applyFilters } = this.props;
        const minDate = this.data[Math.min(start, end)].date.format(SERVER_DATE_FORMAT);
        const maxDate = this.data[Math.max(start, end)].date.format(SERVER_DATE_FORMAT);
        applyFilters(`${minDate},${maxDate}`);
    }

    render() {
        const { format } = this.props;
        return (
            <ResponsiveContainer>
                <AreaChart data={this.data} margin={{ top: 0, right: 0, bottom: 8, left: 0 }} onMouseDown={this.onMouseDown}>
                    <defs>
                        <linearGradient id="fillColor" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={theme.primaryColor} stopOpacity={0.8}/>
                            <stop offset="95%" stopColor={theme.primaryColor} stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <Customized component={<Selection innerRef={this.selectionRef} />} />
                    <Tooltip labelFormatter={(index) => this.data[index]?.text ?? ''} formatter={(value) => format(value)} />
                    <Area type="monotone" dataKey="value" stroke={theme.primaryColor} fill="url(#fillColor)" />
                </AreaChart>
            </ResponsiveContainer>
        );
    }
}
