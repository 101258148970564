import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { UserStore } from './User';
import { DocumentStore } from './Document';
import { Casts } from './Base';


export class Customer extends Model {
    static omitFields = ['facilityStatus'];
    static backendResourceName = 'customer';
    static STATUSES = ['Not connected', 'FON', 'EON', 'Active', 'Archived'];
    static OWNERS = ['Customer', 'DSO', 'OTSO'];

    @observable id = null;
    @observable name = '';
    @observable status = 'Not connected';
    @observable owner = 'Customer';
    @observable facilityStatus = null;
    @observable terminationDate = null;
    @observable lastReconfirmationDate = null;

    @observable data = {};
    @observable objectData = {};
    @observable groupData = {};
    @observable lineData = {};

    get deleted() {
        return this.status === 'Archived';
    }

    set deleted(value) {
        if (value) {
            this.status = 'Archived';
        } else if (this.status === 'Archived') {
            this.status = 'Not connected';
        }
    }

    async delete() {
        await this.api.put(this.url, { status: 'Archived' });
        this.status = 'Archived';
    }

    async restore() {
        await this.api.put(this.url, { status: 'Not connected' });
        this.status = 'Not connected';
    }

    relations() {
        return {
            users: UserStore,
            documents: DocumentStore,
        };
    }

    casts() {
        return {
            terminationDate: Casts.date,
            lastReconfirmationDate: Casts.date,
        };
    }
}

export class CustomerStore extends Store {
    Model = Customer;
    static backendResourceName = 'customer';
}
