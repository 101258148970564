import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class MyFilter extends Model {
    static backendResourceName = 'my_filter';

    @observable id = null;
    @observable view = '';
    @observable name = '';
    @observable params = {};
    @observable default = false;

    setDefault() {
        this.default = true;
        return this.save().catch((err) => {
            this.default = false;
            return Promise.reject(err);
        });
    }

    unsetDefault() {
        this.default = false;
        return this.save().catch((err) => {
            this.default = true;
            return Promise.reject(err);
        });
    }

    setParams(params) {
        const oldParams = this.params;
        this.params = params;
        return this.save().catch((err) => {
            this.params = oldParams;
            return Promise.reject(err);
        });
    }
}

export class MyFilterStore extends Store {
    Model = MyFilter;
    static backendResourceName = 'my_filter';
}
