import PropTypes from 'prop-types';
import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Icon, Header, Form, Button, Input, Message, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { theme } from 'styles';
import styled from 'styled-components';
import { lighten } from 'polished';
import SidebarScreen from './SidebarScreen';

export const PrimaryLinkContainer = styled.div`
    text-align: right;
    margin-top: -0.25rem;
    margin-bottom: 0.75rem;
`;

export const PrimaryLink = styled(Link)`
    color: ${theme.primaryColor};
    &:hover {
        color: ${lighten(0.1, theme.primaryColor)};
    }
    font-weight: bold;
`;

const ShowPasswordIcon = styled(Icon)`
    position: absolute;
    color: rgba(0, 0, 0, 0.87) !important;
    opacity: 0.3 !important;
    &:hover {
        opacity: 0.6 !important;
    }
    transition: opacity 300ms ease;
    cursor: pointer;
    margin: 0 !important;
    width: 2.57142857em !important;
    height: 2.57142857em !important;
    line-height: 2.57142857em !important;
    right: 1px;
    bottom: 1px;
`;

@observer
export default class Login extends SidebarScreen {
    static propTypes = {
        viewStore: PropTypes.object,
        usernameField: PropTypes.string.isRequired,
        afterLogin: PropTypes.func,
    };

    static defaultProps = {
        usernameField: 'email',
    };
    @observable errorMsg = '';
    @observable input = { password: '', [this.props.usernameField]: '' };
    @observable showPassword = false;

    handleSubmit = () => {
        const { usernameField, afterLogin } = this.props;

        this.errorMsg = '';
        this.props.viewStore
            .performLogin(this.input[usernameField], this.input.password, { usernameField: usernameField })
            .then(afterLogin)
            .catch(err => {
                const status = err.response && err.response.status;
                if (status === 403) {
                    this.errorMsg = t('user.login.errors.invalidCredentials');
                } else {
                    console.error(err);
                    this.errorMsg = t('user.login.errors.unknown', {
                        status: status || err.message,
                    });
                }
            });
    };

    renderHeader() {
        return (
            <Header as="h2" content={t('user.login.title')} />
        );
    }

    renderForgotPasswordLink() {
        return (
            <PrimaryLinkContainer>
                <PrimaryLink to="/login/forgot">
                    {t('user.login.forgotPasswordLink')}
                </PrimaryLink>
            </PrimaryLinkContainer>
        );
    }

    renderForm() {
        const { usernameField, labelColor, submitButtonColor } = this.props;

        return (
            <Form error={this.errorMsg !== ''} style={{ marginTop: '1.5rem' }}>
                <Form.Field>
                    <label style={{'color': labelColor}}>{t(`user.field.${usernameField}.label`)}</label>
                    <Input
                        name={usernameField}
                        value={this.input[usernameField]}
                        onChange={(e, { value }) => this.input[usernameField] = value}
                        autoFocus
                    />
                </Form.Field>
                <Form.Field style={{ position: 'relative' }}>
                    <label style={{'color': labelColor}}>{t('user.field.password.label')}</label>
                    <Input
                        name="password"
                        type={this.showPassword ? undefined : 'password'}
                        value={this.input.password}
                        onChange={(e, { value }) => this.input.password = value}
                    />
                    <ShowPasswordIcon
                        name={this.showPassword ? 'eye slash' : 'eye'}
                        onClick={() => this.showPassword = !this.showPassword}
                    />
                </Form.Field>
                {this.renderForgotPasswordLink()}
                <Message error content={this.errorMsg} />
                <Button style={{'backgroundColor': submitButtonColor}} data-test-login data-test-login-button primary fluid
                    content={t('user.login.loginButton')}
                    onClick={this.handleSubmit}
                />
            </Form>
        );
    }

    renderContent() {
        return (
            <React.Fragment>
                {this.renderHeader()}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

@observer
export class LoginModal extends Login {
    renderForgotPasswordLink() {
        return null;
    }

    render() {
        return (
            <Modal open
                centered={false}
                size="mini"
            >
                <Modal.Header>{t('user.login.title')}</Modal.Header>
                <Modal.Content>
                    <p>{t('user.login.reloginMessage')}</p>
                    {this.renderForm()}
                </Modal.Content>
            </Modal>
        );
    }
}
