import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { Sidebar } from 're-cy-cle';
import styled from 'styled-components';
import { IconButton } from '../semantic-ui/Button';

const FloatyBoi = styled.div`
    position: fixed;
    right: 0;
    z-index: 410;
    height: calc(100% - 100px);
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.15);

    > aside {
        /* I shouldn't do this hack, yet I do */
        /* tsk tsk */
        height: 100%;
    }
`;

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 0px;
    right: 0px;
`;

export const Toggle = styled.div`
    > * {
        position: absolute;
        padding: 5px;
        color: ${props => props.theme.primaryColor};
        right: ${props => props.index * 45 + 5}px;
        top: 57px;
        width: 36px !important;
        height: 36px !important;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 30px;
        cursor: pointer;
        display: flex !important;

        ${props => props.isActive && `
            background: rgba(0, 0, 0, 0.6);
        `};
    }
`;

@observer
export default class FloatingSidebar extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        trigger: PropTypes.node,
        index: PropTypes.number.isRequired,
        className: PropTypes.string,
        onClose: PropTypes.func,
        show: PropTypes.bool,
    };

    static defaultProps = {
        index: 0,
    };

    @observable isShown = false;

    @computed get shouldShow() {
        return Object.keys(this.props).includes('show')
            ? this.props.show
            : this.isShown;
    }

    toggleShown() {
        this.isShown = !this.isShown;
    }

    renderToggle() {
        const { show, trigger, index } = this.props;

        return (
            <Toggle data-test-floating-sidebar-toggle={index} index={index} isActive={show}>
                {typeof trigger === 'function' ? trigger({ onClick: this.toggleShown.bind(this) }) : trigger }
            </Toggle>
        );
    }

    render() {
        const { show, children, trigger, index, className, onClose, ...rest } = this.props;
        const triggerButton = this.renderToggle();

        if (!this.shouldShow) {
            return triggerButton;
        }

        return (
            <React.Fragment>
                {triggerButton}
                <FloatyBoi className={className}>
                    <Sidebar {...rest}>
                        {onClose && <StyledIconButton name="close" onClick={onClose} />}
                        {children}
                    </Sidebar>
                </FloatyBoi>
            </React.Fragment>
        );
    }
}
