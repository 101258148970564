import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Customer } from './Customer';

export class Document extends Model {
    static backendResourceName = 'document';

    @observable id = null;
    @observable file = null;

    relations() {
        return {
            customer: Customer,
        };
    }
}

export class DocumentStore extends Store {
    Model = Document;
    static backendResourceName = 'document';
}
