
const SEVERITY_COLORS = {
    'info': '#F6D420',
    'warning': '#E08735',
    'error': '#C90000',

}

/**
 *
 * @param data
 * @returns {*}
 */
export function severityTransformer(data) {

    const transformedData = data;
    if ('severity' in transformedData) {
        const severity = transformedData['severity']
        delete transformedData['severity'];

        transformedData['data-test-severity'] = severity;

        // If we have the severity logged as colors, add a color
        if (severity in SEVERITY_COLORS) {
            // 55 = 20% opacity
            transformedData['background'] = `${SEVERITY_COLORS[severity]}55`;
            transformedData['indicator'] = `${SEVERITY_COLORS[severity]}`;
        }
    }

    return transformedData;
}